import { AppBar, Grid, isWidthUp, SvgIcon, Toolbar, Typography } from "@material-ui/core"
import { StringParam, useQueryParam } from "use-query-params"
import { PublicProfile, publicProfile } from "../services/auth"
import Paper from "@material-ui/core/Paper"
import React, { useEffect } from "react"
import { createStyles, makeStyles, MuiThemeProvider, Theme } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import { ReactComponent as India } from "../../static/india-map-country-svgrepo-com.svg"
import CopyrightIcon from "@material-ui/icons/Copyright"
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord"
import Container from "@material-ui/core/Container"
import { ReactComponent as EmailSent } from "../../static/email-sent.svg"
import withWidth from "@material-ui/core/withWidth"
import theme from "../theme"
import CssBaseline from "@material-ui/core/CssBaseline"
import Avatar from "@material-ui/core/Avatar"
import Graph from "../components/feedback/received/graph"
import ToggleButton from "@material-ui/lab/ToggleButton"
import { getQualities } from "../services/data"
import { AccountCircle } from "@material-ui/icons"
import { navigate } from "gatsby"
import { Policy } from "../components/policy"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root1: {
      color: theme.palette.text.secondary,
      borderRadius: 10,
      marginBottom: 50,
      paddingBottom: 40,
      width: '100%',
    },
    send: {
      textTransform: "initial",
      fontSize: 16,
      justifyItems: "center",
      backgroundColor: "rgb(0,76,64)",
      fontWeight: 600,
      color: "#fff",
      borderRadius: 10,
      height: "45px",
      // width: "80px",
      "&:disable": {
        backgroundColor: "rgb(72,169,153)",
        color: "#fff"
      },
      "&:hover": {
        fontWeight: 600,
        backgroundColor: "rgb(0,121,107)",
        color: "rgb(255,255,255)"
      }
    },
    avatar: {
      width: theme.spacing(25),
      height: theme.spacing(25),
      margin: theme.spacing(1),
      marginBottom: theme.spacing(1),
      cursor: `pointer`
    },
    break: {
      alignItems: "center",
      textAlign: "center",
      fontWeight: 700,
      padding: 20,
      backgroundColor: "rgba(100,181,246,0.61)"
    },
    buttonReq: {
      marginTop: theme.spacing(1),
      borderRadius: 20,
      textTransform: "initial",
      fontWeight: 600,
      fontSize: 17
    },
    rootscroll: {
      flexGrow: 1,
      height: "100%"
    },
    logoText: {
      color: "rgb(189,189,189)"
      // alignItems: "flex-start"
    },
    optionButton: {
      marginBottom: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    skillButton: {
      height: 35,
      fontSize: 14,
      textTransform: "initial",
      lineHeight: 1,
      fontWeight: 500,
      borderRadius: 20,
      borderColor: "rgb(0,74,63,0.5)",
      // border: "0",
      color: "#000000",
      backgroundColor:  "rgb(72,166,151, 0.2)",
    },
    graph: {
      minHeight: 300,
      padding: theme.spacing(1),
      textAlign: "center",
      color: theme.palette.text.secondary,
      borderRadius: 10
    },
    appBar: {
      boxShadow: "none",
      height: 60,
      marginBottom: theme.spacing(0),
      backgroundColor: "rgb(0,0,0)"
    },
    brand: {
      margin: 0,
      // fontFamily: "Merriweather",
      fontWeight: 900,
      fontSize: 24,
      color: "#fff"
      // boxShadow: "4px 4px 4px 2px rgb(255 255 255 / 26%)"
    },
    rootFeedback: {
      flexGrow: 1,
      height: "100%"
      // margin: theme.spacing(0),
    },
    paperSummary: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(2),
      borderRadius: 10
    },
    username: {
      color: "#000000",
      fontWeight: 600,
      marginBottom: 10,
      marginTop: 20,
      fontSize: 20,
      borderBottom: 0,
      "&:before": {
        borderBottom: 0
      }
    },
    userDesc: {
      color: "#5f5e5e",
      borderBottom: 0,
      marginBottom: 50,
      "&:before": {
        borderBottom: 0
      }
    },
  })
)

export function Profile({width}) {
  const classes = useStyles()
  const [uid] = useQueryParam("uid", StringParam)
  console.log("uid", uid)
  const [profile, setProfile] = React.useState<PublicProfile>()
  if (uid != null) {
    useEffect(() => {
      publicProfile(uid).then(r => {
        setProfile(r)
        console.log("public profile data", r)
      })
    },[])
  }
  const [openTerms, setOpenTerms] = React.useState(false);

  const handleClickOpen = () => {
    setOpenTerms(true)
  }

  const handleClose = () => {
    setOpenTerms(false)
  }

  if (isWidthUp("lg", width)) {
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline/>
        <div className={classes.rootscroll}>
          <AppBar position="sticky" className={classes.appBar}>
            <Toolbar>
              <Container maxWidth="lg">
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <Typography classes={{ root: classes.brand }}>Johari</Typography>
                  <Grid style={{alignSelf: "flex-end"}}>
                    <Button onClick={()=> {
                      typeof window !== `undefined` && navigate("/user")
                    }}
                            className={classes.send} startIcon={<AccountCircle style={{ height: 30, width: 30 }}/>} variant="contained"  color="primary" >
                      My Profile
                    </Button>
                  </Grid>
                  {/*<CustomTab*/}
                  {/*  value={0}*/}
                  {/*  onChange={handleChange}*/}
                  {/*  style={{ margin: 0 }} label={<span className={classes.brand}>Johari</span>}*/}
                  {/*  // icon={<img src={Logo} className={classes.logo} alt="logo"/>}*/}
                  {/*  {...a11yProps(0)} />*/}

                </Grid>

              </Container>
            </Toolbar>
          </AppBar>

          <Toolbar style={{minHeight: 10}}/>
          <Container maxWidth="lg">

            <div className={classes.rootFeedback}>
              <Grid container>
                <Grid item xs={3}>

                  {/*summary*/}
                  <Grid container>
                    <Grid item container direction={"column"}>
                      <Paper className={classes.paperSummary}>
                        <Grid container direction="column" alignItems="center">
                          <Grid item >
                            <input accept="image/*"  style={{ display: `none`, position: "absolute" }}
                                   id="icon-button-file" type="file"/>
                            <Avatar key={profile?.image} className={classes.avatar} src={profile?.image} aria-label="upload picture">Image</Avatar>
                          </Grid>
                          <Grid container justify="center">
                            <Grid item container justify="center">
                              <Typography classes={{root: classes.username}}> {profile?.name} </Typography>
                            </Grid>
                            <Grid item container justify="center">
                              <Typography classes={{root: classes.userDesc}}> {profile?.desc} </Typography>
                            </Grid>

                          </Grid>
                          {profile?.created && (
                            <Grid item>
                              <Typography variant={"caption"}>Member since {new Date(profile?.created!).toDateString()}</Typography>
                            </Grid>
                          )}
                        </Grid>
                      </Paper>
                      <Grid container justify={"flex-start"} alignItems={"center"} style={{ marginTop: 30, paddingLeft: 10 }}>
                        {/* yoksel.github.io/url-encoder/*/}
                        <Grid item xs={3} style={{ height: 75, paddingLeft: 40 }} container justify={"center"}>
                          <SvgIcon viewBox={"0 0 280 300"} style={{ height: 140, width: 130 }}>
                            <India/>
                          </SvgIcon>
                        </Grid>
                        <Grid item xs={9} container style={{opacity: 0.5}} direction={"row"} alignItems={"center"} justify={"center"}>
                          <Grid item xs={1}>
                            <CopyrightIcon style={{ alignItems: "flex-end" }} color={"secondary"}/>
                          </Grid>
                          <Grid item xs={11} style={{ paddingLeft: 10 }}>
                            <Typography classes={{ root: classes.logoText }}> 2020 Johariapp</Typography>
                          </Grid>
                          <Grid item xs={12} className={classes.logoText}>
                            <Button onClick={handleClickOpen} className={classes.logoText} startIcon={<FiberManualRecordIcon/>}
                                    endIcon={<FiberManualRecordIcon/>}>Privacy</Button>
                            <Button onClick={handleClickOpen} className={classes.logoText} endIcon={<FiberManualRecordIcon/>}>Terms</Button>
                          </Grid>
                          { open && (
                            <Policy open={openTerms}
                                    handleClose={handleClose}/>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>


                </Grid>
                <Grid item xs={9}>
                  {/* Received*/}
                  <Grid container direction="column" spacing={1}>
                    { profile?.skills.length! < 1 && (
                      <Grid item container style={{marginBottom: 40}}>
                        <Grid item><img alt="graph analysis overview" style={{width: '100%', borderRadius: 10}} src="https://johari.s3.amazonaws.com/public-img/emails/welcome-feedback.png"/>
                        </Grid>
                      </Grid>
                    )}
                    {  profile?.skills.length!  > 0 && (
                      <Grid item>
                        <Paper className={classes.graph}>
                          <Graph skills={profile?.skills!} />
                          {profile?.qualities.length! > 0 && (
                            <Grid item container style={{margin: 20}} justify={"center"}>
                              {
                                profile?.qualities.map((x) => {
                                  console.log("qualitiesp", x)
                                  return(
                                    <Grid item key={x.qid} >
                                      <Grid item className={classes.optionButton}>
                                        <ToggleButton  value={false} className={classes.skillButton}>
                                          {x.Count} {getQualities()[x.qid]}
                                        </ToggleButton>
                                      </Grid>
                                    </Grid>
                                  )
                                })
                              }
                            </Grid>
                          )}
                        </Paper>
                      </Grid>
                    )}

                  </Grid>


                </Grid>
              </Grid>
            </div>

          </Container>
        </div>
      </MuiThemeProvider>
    )
  } else {
    return (
      <Paper className={classes.break} style={{ width: "100%", height: "100ch" }}>
        <Grid container justify={"center"} alignItems={"center"}>
          <Grid container justify={"center"} alignItems={"center"}>
            <SvgIcon style={{ height: "50%", width: "50%", marginTop: 50 }}>
              <EmailSent/>
            </SvgIcon>
          </Grid>
          <Grid container justify={"center"} alignItems={"center"}>
            <Typography classes={{ root: classes.break }} variant={"h6"}> Please choose a screen wider than
              1279px</Typography>
          </Grid>
        </Grid>
      </Paper>
    )
  }
}

export default withWidth()(Profile)